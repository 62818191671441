export let BlobColor;
(function (BlobColor) {
    BlobColor["BLUE"] = "blue";
    BlobColor["GREEN"] = "green";
    BlobColor["LILAC"] = "lilac";
    BlobColor["PINK"] = "pink";
    BlobColor["PURPLE"] = "purple";
    BlobColor["YELLOW"] = "yellow";
})(BlobColor || (BlobColor = {}));
export let BlobShape;
(function (BlobShape) {
    BlobShape["SHAPE_01"] = "shape_01";
    BlobShape["SHAPE_02"] = "shape_02";
    BlobShape["SHAPE_03"] = "shape_03";
    BlobShape["SHAPE_04"] = "shape_04";
    BlobShape["SHAPE_05"] = "shape_05";
    BlobShape["SHAPE_06"] = "shape_06";
    BlobShape["SHAPE_07"] = "shape_07";
    BlobShape["SHAPE_08"] = "shape_08";
    BlobShape["SHAPE_09"] = "shape_09";
    BlobShape["SHAPE_10"] = "shape_10";
    BlobShape["SHAPE_11"] = "shape_11";
})(BlobShape || (BlobShape = {}));
export let BlobPosition;
(function (BlobPosition) {
    BlobPosition["CENTRE"] = "centre";
    BlobPosition["NORTH"] = "north";
    BlobPosition["NORTH_EAST"] = "north_east";
    BlobPosition["EAST"] = "east";
    BlobPosition["SOUTH_EAST"] = "south_east";
    BlobPosition["SOUTH"] = "south";
    BlobPosition["SOUTH_WEST"] = "south_west";
    BlobPosition["WEST"] = "west";
    BlobPosition["NORTH_WEST"] = "north_west";
})(BlobPosition || (BlobPosition = {}));
